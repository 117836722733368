<template>
  <div class="animated fadeIn">
    <CardTable
      :title="title"
      :columns="table.columns"
      :rows="table.rows"
      :isLoading="table.isLoading"
      :pageSize="serverParams.pageSize"
      :pageNumber="serverParams.pageNumber"
      :totalPage="table.totalPage"
      :totalRecords="table.totalRecords"
      @onPageChange="onPageChange"
    >
      <template #action>
        <div class="card-header-actions">
          <b-button @click="$router.go(-1)" size="sm"> Back </b-button>
        </div>
      </template>
      <template #cell(period)="data">
        {{ `${parseMonth(data.item.readingMonth)}/${data.item.readingYear}` }}
      </template>
      <template #cell(previousReadingDate)="data">
        {{ dateFormat(data.item.previousReadingDate) }}
      </template>
      <template #cell(currentReadingDate)="data">
        {{ dateFormat(data.item.currentReadingDate) }}
      </template>
    </CardTable>
  </div>
</template>

<script>
import { parseMonth, dateFormat } from "@/shared/utils";
import { meterReadingsHistory as columns } from "@/shared/columns";

export default {
  data: () => ({
    title: null,
    serverParams: {
      pageNumber: 1,
      pageSize: 20,
      keyword: null,
      orderBy: "readingYear desc,readingMonth desc",
    },
    table: {
      isLoading: true,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
  }),
  created() {
    const self = this;
    self.title = `Meter Readings History (#${this.$route.params.id})`;
    self.getAll();
  },
  methods: {
    parseMonth,
    dateFormat,
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ pageNumber: params });
      this.getAll();
    },
    getAll() {
      const self = this;

      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: `/meterreading/${self.$route.params.id}`,
          params: self.serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
  },
};
</script>